import React, { useState, useEffect, createContext } from 'react';
import {
    Typography, Box, Button, Stack, AccordionActions, AccordionSummary,
    Card, CardHeader, Accordion, AccordionDetails, TextField, FormControl,
    InputLabel, Select, MenuItem, Alert,
} from '@mui/material';

import { useTournamentContext } from './../../context';
import axios from '../../utils/axios';
import { Link, useParams, useNavigate } from "react-router-dom";
import Form from './Form'

function EditPage() {
    const navigate = useNavigate();
    const { type, id_participant } = useParams();
    const { user, bot, apiURL, participants, proposals, tournament } = useTournamentContext();

    const [ data, setData ] = useState({})
    const [ error, setError ] = useState(false)
    const [ inProgress, setInProgress ] = useState(false)

    useEffect(() => {
        if(type === 'PARTICIPANT'){
            setData(participants.find(p => p.id === parseInt(id_participant)) ?? {})
        }else{
            setData(proposals.find(p => p.id === parseInt(id_participant)) ?? {})
        }
    }, [type, id_participant, participants, proposals])

    const save = async (event) => {
        event.preventDefault()
        if(inProgress){
            return
        }
        setInProgress(true)

        let values = Object.assign({}, data)
        for(const param of Object.keys(values.parameters)){
            if(!document.getElementById(`parameter-${param}`)){
                delete values.parameters[param]
            }
        }

        console.log('VALUES', values)
        const payload = await axios.post(apiURL(`tournament/${tournament.id}/${type.toLowerCase()}s`), values).catch(e => setInProgress(false))

        setInProgress(false)

        if(payload?.data?.id){
            alert('Успішно збережено')
            window.dispatchEvent(new CustomEvent("arena-refresh"));
            navigate(`/${bot}/${tournament.id}`)
        }else{
            setError("Вибачте, сталась помилка. Перезавантажте сторінку і спробуйте ще раз.")
        }
    }

    return (
        <Stack spacing={1} style={{margin: '5px 10px'}} direction="column">
              <Link to={`/${bot}/${tournament?.id}`}>Повернутись до моїх учасників</Link>

              <Typography variant="h5">
                  Редагування учасника {data?.name}
              </Typography>

            {!!error && (
                <Alert severity="error">
                    {error}
                </Alert>
            )}

            {!!data.id && (
                <form onSubmit={(e) => save(e)}>
                    <Form
                        data={data}
                        onChange={value => setData(value)}
                    />
                    <Button type="submit" variant="contained" color="warning" style={{margin: '5px auto'}}>
                        Зберегти
                    </Button>
                </form>
            )}

            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </Stack>
    );
}

export default EditPage;
