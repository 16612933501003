import React, { createContext, useState, useEffect, useContext } from 'react';

import axios from './utils/axios';

const TournamentContext = createContext();

export const TournamentProvider = ({ children }) => {
    const [ user, setUser ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const [ tournament, setTournament ] = useState({})

    const [ participants, setParticipants ] = useState([])
    const [ proposals, setProposals ] = useState([])

    const [ updatedAt, setUpdatedAt ] = useState(new Date())

    const [ autoComplete, setAutoComplete ] = useState({
        name: [],
        phone: [],
        coach: [],
        school: [],
        address: [],
    })

    const path = window.location.pathname;
    const bot = path.split('/')[1];
    const id_tournament = path.split('/')[2];

    useEffect(() => {
        axios.get(apiURL(`tournament/${id_tournament}`)).then(payload => {
            setTournament(payload?.data)
        })

        axios.get(apiURL(`tournament/${id_tournament}/participants`)).then(payload => {
            setParticipants(payload?.data)
        })

        axios.get(apiURL(`tournament/${id_tournament}/proposals`)).then(payload => {
            setProposals(payload?.data)
        })
    }, [id_tournament, updatedAt])

    useEffect(() => {
        const all = participants.concat(proposals)
        setAutoComplete({
            name: [... new Set(all.map(p => p.name).filter(v => v))],
            phone: [... new Set(all.map(p => p.phone).filter(v => v))],
            coach: [... new Set(all.map(p => p.coach).filter(v => v))],
            school: [... new Set(all.map(p => p.school).filter(v => v))],
            address: [... new Set(all.map(p => p.address).filter(v => v))],
        })
    }, [participants, proposals])

    const apiURL = url => {
        return `${process.env.REACT_APP_API}/api/festival-bot/${bot}/${url}`
    }

    window.addEventListener("arena-registered", function(event) {
        setUpdatedAt(new Date())
    });

    window.addEventListener("arena-refresh", function(event) {
        setUpdatedAt(new Date())
    });

    useEffect(() => {
        setLoading(true);
        axios.get(apiURL('me')).then(payload => {
            setLoading(false);
            if(payload?.data?.id){
                setUser(payload?.data)
            }
        })
    }, [])

    const value = {
        user, tournament, bot, loading, apiURL, participants, proposals,
        autoComplete,
    };

    return <TournamentContext.Provider value={value}>{children}</TournamentContext.Provider>;
};

export const useTournamentContext = () => useContext(TournamentContext);
