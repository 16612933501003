import React, { useState, useEffect, createContext } from 'react';
import {
    Typography, Box, Button, Stack, AccordionActions, AccordionSummary,
    Card, CardHeader, Accordion, AccordionDetails, TextField, FormControl,
    InputLabel, Select, MenuItem, Alert, Autocomplete
} from '@mui/material';

import { useTournamentContext } from './../../context';
import axios from '../../utils/axios';
import { useParams, useNavigate } from "react-router-dom";

function Form({ data, onChange }) {
    const { user, bot, apiURL, tournament, autoComplete } = useTournamentContext();

    const isHidden = (key, param) => {
        return (param['hidden'] ?? []).includes(`${key}`)
    }

    return (
        <Stack spacing={1} direction="column" justifyContent="center" alignItems="stretch">
            <Autocomplete
                freeSolo
                options={autoComplete.name}
                value={data.name}
                onInputChange={(e, value) => onChange({...data, name: value})}
                renderInput={(params) => <TextField
                    {...params}
                    variant="outlined"
                    placeholder={tournament?.additional_settings?.telegram?.labels?.['participant-name'] ?? 'ПІБ учасника(ів) / Назва колективу'}
                    required
                />}
            />
            {!(tournament?.additional_settings?.registration || {})['skip-phone'] && (
                <Autocomplete
                    freeSolo
                    options={autoComplete.phone}
                    value={data.phone}
                    onInputChange={(e, value) => onChange({...data, phone: value})}
                    renderInput={(params) => <TextField
                        {...params}
                        variant="outlined"
                        placeholder={tournament?.additional_settings?.telegram?.labels?.phone ?? 'Телефон'}
                        required
                    />}
                />
            )}
            {!(tournament?.additional_settings?.registration || {})['skip-address'] && (
                <Autocomplete
                    freeSolo
                    options={autoComplete.address}
                    value={data.address}
                    onInputChange={(e, value) => onChange({...data, address: value})}
                    renderInput={(params) => <TextField
                        {...params}
                        variant="outlined"
                        placeholder={tournament?.additional_settings?.telegram?.labels?.address ?? 'Місто'}
                        required
                    />}
                />
            )}
            {!(tournament?.additional_settings?.registration || {})['skip-coach'] && (
                <Autocomplete
                    freeSolo
                    options={autoComplete.coach}
                    value={data.coach}
                    onInputChange={(e, value) => onChange({...data, coach: value})}
                    renderInput={(params) => <TextField
                        {...params}
                        variant="outlined"
                        placeholder={tournament?.additional_settings?.telegram?.labels?.coach ?? 'Тренер'}
                        required
                    />}
                />
            )}
            {!(tournament?.additional_settings?.registration || {})['skip-school'] && (
                <Autocomplete
                    freeSolo
                    options={autoComplete.school}
                    value={data.school}
                    onInputChange={(e, value) => onChange({...data, school: value})}
                    renderInput={(params) => <TextField
                        {...params}
                        variant="outlined"
                        placeholder={tournament?.additional_settings?.telegram?.labels?.school ?? 'Назва студії/школи'}
                        required
                    />}
                />
            )}

            {!(tournament?.additional_settings?.registration || {})['skip-title'] && (
                <TextField
                    variant="outlined"
                    placeholder={tournament?.additional_settings?.telegram?.labels?.title ?? 'Назва номеру'}
                    value={data.title}
                    onChange={e => onChange({...data, title: e.target.value})}
                    required
                    autoComplete="off"
                />
            )}

            {(tournament?.definition_participant_additional_parameters || [])
                .filter(p => p.type === 'buttons')
                .filter(p => !p.conditions || !(p.conditions || []).map(c => c.value.includes((data?.parameters || {})[c.key])).filter(v => !v).length)
                .map(param => (
                    <FormControl fullWidth>
                        <InputLabel>{param.name}</InputLabel>
                        <Select
                            value={(data?.parameters || {})[param.key] || ''}
                            label={param.name}
                            required
                            id={`parameter-${param.key}`}
                            onChange={type => onChange({...data, parameters: {...data?.parameters, [param.key]: type.target.value}})}
                        >
                            <MenuItem value="">Оберіть значення</MenuItem>
                            {Object.keys(param.values).filter(k => !isHidden(k, param)).map(k => (
                                    <MenuItem key={k} value={k}>{param.values[k]}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
            ))}

            {(tournament?.definition_participant_additional_parameters || [])
                .filter(p => p.type === 'message')
                .filter(p => !p.conditions || !(p.conditions || []).map(c => c.value.includes((data?.parameters || {})[c.key])).filter(v => !v).length)
                .map(param => (
                    <TextField
                        variant="outlined"
                        key={param.key}
                        id={`parameter-${param.key}`}
                        placeholder={param.name}
                        value={(data?.parameters || {})[param.key] || ''}
                        onChange={type => onChange({...data, parameters: {...data?.parameters, [param.key]: type.target.value}})}
                        required
                        autoComplete="off"
                    />
            ))}
        </Stack>
    );
}

export default Form;
