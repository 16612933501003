import * as React from 'react';
import {
    AppBar, Toolbar, Typography, Button
} from '@mui/material';
import MainMenu from './Menu'
import { useNavigate, useLocation} from "react-router-dom";
import { useTournamentContext } from './context';

function AppToolbar() {
    const navigate = useNavigate();
    const { tournament, bot } = useTournamentContext();

    const [title, setTitle] = React.useState(null);

    const location = useLocation();
    React.useEffect(() => {
        if(location.pathname.includes('registration')){
            setTitle('Реєстрація');
        }else{
            setTitle('Учасники');
        }
    }, [location.pathname]);

    return (
        <AppBar position="static" color="info">
            <Toolbar>
                <MainMenu />

                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>{title}</Typography>

                {!tournament?.block_registration_from_telegram && (
                    <Button variant="contained" color="warning" onClick={() => navigate(`/${bot}/${tournament.id}/registration`)}>
                        Реєстрація
                    </Button>
                )}
            </Toolbar>
        </AppBar>
    );
}

export default AppToolbar;
