import React, { useState, useEffect, createContext } from 'react';
import {
    Typography, Box, Button, Stack, AccordionActions, AccordionSummary,
    Card, CardHeader, Accordion, AccordionDetails, TextField, FormControl,
    InputLabel, Select, MenuItem, Alert,
} from '@mui/material';

import { useTournamentContext } from './../../context';
import axios from '../../utils/axios';
import { Link, useParams, useNavigate } from "react-router-dom";

import instruction1_1 from '../../assets/images/document/instruction1_1.png'
import instruction1_2 from '../../assets/images/document/instruction1_2.png'
import instruction1_3 from '../../assets/images/document/instruction1_3.png'

import instruction2_1 from '../../assets/images/document/instruction2_1.png'

function DocumentPage() {
    const navigate = useNavigate();
    const fileRef = React.createRef()

    const { type, id_participant, document_type } = useParams();
    const { user, bot, apiURL, participants, proposals, tournament } = useTournamentContext();

    const [ participant, setParticipant ] = useState({})

    const [ success, setSuccess ] = useState(false);
    const [ error, setError ] = useState(null);
    const [ progress, setProgress ] = useState(false);
    const [ successURL, setSuccessURL ] = useState(false);
    const [ errorURL, setErrorURL ] = useState(null);
    const [ progressURL, setProgressURL ] = useState(false);
    const [ googleDriveUrl, setGoogleDriveUrl ] = useState('');
    const [ showInstruction1, setShowInstruction1 ] = useState(false);
    const [ showInstruction2, setShowInstruction2 ] = useState(false);

    const toogleInstruction1 = () => setShowInstruction1(!showInstruction1)
    const toogleInstruction2 = () => setShowInstruction2(!showInstruction2)

    useEffect(() => {
        if(type === 'PARTICIPANT'){
            setParticipant(participants.find(p => p.id === parseInt(id_participant)) ?? {})
        }else{
            setParticipant(proposals.find(p => p.id === parseInt(id_participant)) ?? {})
        }
    }, [type, id_participant, participants, proposals])


    const urlParams = new URLSearchParams(window.location.search);
    const document_name = decodeURI(urlParams.get('name'));

    const upload = async (document) => {
        setProgress(true)
        setError(false)

        const formData = new FormData();
        formData.append('type', type);
        formData.append('id', participant.id);
        formData.append('id_tournament', participant.id_tournament);
        formData.append('document_type', document_type);
        formData.append('document', document);

        const data = await axios.post(apiURL(`tournament/${participant?.id_tournament}/document`), formData, {headers: {
            'content-type': 'multipart/form-data',
        }}).catch(e => {
            setError(true)
        })

        setProgress(false)

        if(data?.data?.id){
            setSuccess(true);
            setError(false)
            window.dispatchEvent(new CustomEvent("arena-refresh"));
        }else{
            setError(true)
        }
    }

    const sendGoogleDriveURL = async () => {
        const parts = googleDriveUrl.split('/')
        if(
            parts.length != 7
            || !parts[0].includes("https:")
            || !parts[2].includes("drive.google.com")
            || !parts[3].includes("file")
            || !parts[4].includes("d")
        ){
            alert('Посилання невірне, будь-ласка, перевірте правильність посилання')
            return;
        }

        setProgressURL(true)
        setErrorURL(false)

        const formData = new FormData();
        formData.append('type', type);
        formData.append('id', participant.id);
        formData.append('id_tournament', participant.id_tournament);
        formData.append('document_type', document_type);
        formData.append('document', document);
        formData.append('url', googleDriveUrl);

        const data = await axios.post(apiURL(`tournament/${participant?.id_tournament}/document`), formData, {headers: {
            'content-type': 'multipart/form-data',
        }}).catch(e => {
            setErrorURL(true)
        })

        setProgressURL(false)

        if(data?.data?.id){
            setSuccessURL(true);
            setErrorURL(false)
            setGoogleDriveUrl('')
            window.dispatchEvent(new CustomEvent("arena-refresh"));
        }else{
            setErrorURL(true)
        }
    }

    return (
        <Stack spacing={1} style={{margin: '5px 10px'}} direction="column">
              <Link to={`/${bot}/${tournament?.id}`}>Повернутись до моїх учасників</Link>

              <Typography variant="h5">
                  Завантаження "{document_name}" для учасника {participant?.name} до турніру {tournament?.name}
              </Typography>

            <Typography>
                Будь-ласка, натисніть на кнопку "Завантажити" та оберіть файл на вашому пристрої.
            </Typography>
            <Typography style={{color: 'red', fontWeight: 400}}>
                УВАГА: якщо розмір файлу більше ніж 100Мб - треба скористатись завантаженням через Google Drive
            </Typography>
            <input type="file" onChange={(e) => upload(e.target.files[0])} ref={fileRef} style={{display: 'none'}} />

            {!success && !progress && (
                <Button color="info" variant="contained" onClick={() => fileRef.current.click()}>
                    Завантажити
                </Button>
            )}

            {!!progress && (
                <Typography variant="h6" style={{textAlign: 'center', color: 'blue'}}>
                    Завантажується...
                </Typography>
            )}

            {!!success && (
                <Typography variant="h6" style={{textAlign: 'center', color: 'green'}}>
                    Успішно завантажено
                </Typography>
            )}

            {!!error && (
                <Typography variant="h6" style={{textAlign: 'center', color: 'red'}}>
                    Помилка завантаження. Спробуйте ще раз.
                </Typography>
            )}

            <br />
            <br />
            <Typography>
                Або ви можете скористатись завантаженням відео за допомогою посилання на Google Drive
            </Typography>

            <Typography style={{color: 'red', fontWeight: 400}}>
                УВАГА: максимальний розмір файлу для завантаження через Google Drive - 1.5Gb
            </Typography>
            <Typography>Будь-ласка, виконайте наступні кроки:</Typography>
            <ul>
                <li>1. Завантажте відео на https://drive.google.com/ (у вас має бути аккаунт google)</li>
                <li>
                    2. Натисніть 'Поділитись' на сторінці відео, або через меню у переліку файлів (<span style={{color: 'blue'}} onClick={() => toogleInstruction1()}>{showInstruction1 ? 'сховати інструкцію' : 'показати інструкцію'}</span>)
                    {!!showInstruction1 && (
                        <div >
                            <img src={instruction1_1} style={{width: '300px', margin: '10px'}} />
                            <img src={instruction1_2} style={{width: '300px', margin: '10px'}} />
                            <img src={instruction1_3} style={{width: '200px', margin: '10px'}} />
                        </div>
                    )}
                </li>
                <li>
                    3. Надйте доступ 'Усім, хто має посилання' - 'Може переглядати' (<span style={{color: 'blue'}} onClick={() => toogleInstruction2()}>{showInstruction2 ? 'сховати інструкцію' : 'показати інструкцію'}</span>)
                    {!!showInstruction2 && (
                        <div >
                            <img src={instruction2_1} style={{width: '500px', margin: '10px'}} />
                        </div>
                    )}
                </li>
                <li>4. Натисніть на кнопку 'Копіювати посилання'</li>
                <li>5. Вставте посилання в поле нижче. Має бути текст виду 'https://drive.google.com/file/d/...file_id.../view?usp=sharing'</li>
            </ul>

            <TextField
                placeholder="Посилання на google drive"
                value={googleDriveUrl}
                onChange={e => setGoogleDriveUrl(e.target.value)}
                autoComplete="off"
            />

            {!!progressURL && (
                <Typography variant="h6" style={{textAlign: 'center', color: 'blue'}}>
                    Завантажується...
                </Typography>
            )}

            {!!successURL && (
                <Typography variant="h6" style={{textAlign: 'center', color: 'green'}}>
                    Успішно завантажено
                </Typography>
            )}

            {!!errorURL && (
                <Typography variant="h6" style={{textAlign: 'center', color: 'red'}}>
                    Помилка завантаження. Спробуйте ще раз.
                </Typography>
            )}

            {!successURL && !progressURL && (
                <Button color="info" variant="contained" onClick={() => sendGoogleDriveURL()}>
                    Відправити
                </Button>
            )}
            <br />
            <br />
        </Stack>
    );
}

export default DocumentPage;
