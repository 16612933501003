import React, { createContext, useState, useEffect, useContext } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
    Typography, Box, IconButton, Button,
    Card, CardHeader, CardContent, List, ListItem
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { useTournamentContext } from './context';
import axios from './utils/axios';

export default function MainMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const { bot, apiURL, tournament } = useTournamentContext();

    const [ tournaments, setTournaments ] = useState([])

    useEffect(() => {
        axios.get(apiURL('tournament')).then(payload => {
            setTournaments(payload?.data)
        })
    }, [])

    return (
      <div>
        <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
            onClick={handleClick}
        >
            <MenuIcon />
        </IconButton>

        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        >
            {tournaments.map(t => (
                <MenuItem onClick={handleClose} component={Link} to={`/${bot}/${tournament.id}`}>{t.name}</MenuItem>
            ))}
        </Menu>
      </div>
    );
}
