import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';

import {store, persistor} from './redux/store'
import { TournamentProvider } from './context';

import axios from './utils/axios';
axios.defaults.headers.common['X-Telegram-Token'] = window.Telegram.WebApp.initData;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <TournamentProvider>
            <ReduxProvider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <App />
                </PersistGate>
            </ReduxProvider>
        </TournamentProvider>
    </React.StrictMode>
);
