import React, { useState, useEffect, useRef } from 'react';
import {
    Typography, Box, IconButton, Button, Stack, AccordionActions, AccordionSummary,
    Card, CardHeader, CardContent, List, ListItem, Accordion, AccordionDetails,
    Avatar, Tooltip, MenuItem, Menu,
} from '@mui/material';
import ReactPlayer from 'react-player'
import { Link, useNavigate } from "react-router-dom";
import Video from './Video'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MusicOffIcon from '@mui/icons-material/MusicOff';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import InfoIcon from '@mui/icons-material/Info';
import Iconify from '../../components/iconify';
import Icon from '@mui/material/Icon';
import MenuIcon from '@mui/icons-material/Menu';
import { useTournamentContext } from './../../context';

import { styled } from '@mui/material/styles';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const Participant = ({ participant, tournament, onUploadPayment, onUpload, onDelete}) => {
    const { bot } = useTournamentContext();
    const navigate = useNavigate();
    const inputRef = useRef(null);
    const inputRefPayment = useRef(null);
    const [ expanded, setExpanded ] = useState(false)
    const [ viewVideo, setViewVideo ] = useState(false)
    const [ playing, setPlaying ] = useState(false)
    const [ progress, setProgress ] = useState(0)

    const type = participant?.participant?.id ? 'PARTICIPANT' : 'PROPOSAL';

    const [anchorElMenu, setAnchorElMenu] = React.useState(null);
    const openMenu = Boolean(anchorElMenu);
    const handleClickMenu = (event) => {
        event.preventDefault()
        event.stopPropagation()
        setAnchorElMenu(event.currentTarget)
    }
    const handleCloseMenu = (event) => {
        event.preventDefault()
        event.stopPropagation()
        setAnchorElMenu(null)
    }

    const checkParticipantDocument = document => {
        if(!document?.conditions){
            return true
        }
        for(const conditionKey of Object.keys(document.conditions)){
            let checked = false
            const values = document.conditions[conditionKey]?.value
            const k = document.conditions[conditionKey]?.key

            for(const key of values){
                if((participant?.parameters || {})[k] === key){
                    checked = true
                }
            }
            if(!checked){
                return false
            }
        }
        return true
    }

    const [ player, setPlayer ] = useState(null)

    const onPlay = () => {
        player.seekTo(progress)
        setPlaying(true)
    }

    const onProgress = data => {
        if(playing){
            setProgress(data.playedSeconds)
        }
    }

    const onStop = () => {
        setPlaying(false)
        setProgress(0)
    }

    const onPause = () => {
        setPlaying(false)
    }

    const onRepeat = () => {
        player.seekTo(0)
        setPlaying(true)
    }

    const onDownloadMusic = (music) => {
        const link = document.createElement("a");
        link.download = music.filename;
        link.href = music.s3;
        link.target = '_blank'
        link.click();
    };

    const onEdit = () => {
        navigate(`/${bot}/${tournament.id}/edit/${type}/${participant?.id}`)
    }

    return (
        <Accordion slotProps={{ transition: { unmountOnExit: true } }} onChange={(event, e) => setExpanded(e)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Stack direction="column" style={{width: '100%'}}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Avatar sx={{ bgcolor: '#00a5ff', color: 'white' }}>{participant?.participant?.id ? participant?.number : '⏳'}</Avatar>
                        <Stack direction="column">
                            <Typography>
                                {participant?.name}&nbsp;
                                <span style={{color: '#a041f1'}}>{(tournament?.additional_settings?.admin?.participant_additional_fields || []).map(adk =>
                                    (participant?.parameters || {})[adk]
                                ).join(', ')}</span>
                            </Typography>
                            <Typography>
                                {participant?.group_name_short}
                            </Typography>
                        </Stack>
                    </Stack>
                    {!!viewVideo && (
                        <Video
                            open={!!viewVideo}
                            video={viewVideo}
                            onClose={() => setViewVideo(false)}
                        />
                    )}
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" style={{width: '100%'}}>
                        {!participant?.music?.s3
                            ? (
                                <MusicOffIcon color="error"  />
                            )
                            : (
                                <Tooltip title={participant?.music?.filename}>
                                    <MusicNoteIcon color="success" />
                                </Tooltip>
                            )
                        }
                        {!participant?.payment?.s3
                            ? (<MoneyOffIcon color="error"  />)
                            : (<AttachMoneyIcon color={"success"}  />
                        )}

                        {!!participant.is_posa_form_elements_required && (
                            <>
                            {tournament.is_posa && !!participant?.posa_form_elements && (
                                <Tooltip title="Заповнена форма обовʼязкових елементів">
                                    <Iconify icon="material-symbols:sports-gymnastics" style={{color: 'green'}} />
                                </Tooltip>
                            )}
                            {tournament.is_posa && !participant?.posa_form_elements && (
                                <Tooltip title="Немає форми обовʼязкових елементів">
                                    <Iconify icon="material-symbols:sports-gymnastics" style={{color: 'red'}} />
                                </Tooltip>
                            )}
                            </>
                        )}

                        {(tournament?.definition_documents_settings || [])
                            .filter(document => checkParticipantDocument(document))
                            .filter(document => !document.participant_type || document.participant_type === type.toLowerCase())
                            .map(document => {
                              const exists = (participant.documents || []).find(doc => doc.type === document.key)
                              const icon = document.icon
                              if(!icon){
                                  return (<></>)
                              }

                              if(exists){
                                  return (
                                      <Tooltip title={document.name} key={document.key}>
                                          <Icon color="success">{icon}</Icon>
                                      </Tooltip>
                                  )
                              }else{
                                  return (
                                      <Tooltip title={`Без ${document.name}`} key={document.key}>
                                          <Icon color="error">{icon}</Icon>
                                      </Tooltip>
                                  )
                              }
                        })}

                        <IconButton onClick={handleClickMenu}>
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorElMenu}
                            open={openMenu}
                            onClose={handleCloseMenu}
                        >
                            <MenuItem onClick={e => {handleCloseMenu(e); onEdit()}}>
                                <Iconify icon="material-symbols:edit-outline" />&nbsp;Редагувати
                            </MenuItem>
                            {!!onDelete && (<MenuItem onClick={e => {handleCloseMenu(e); onDelete()}}>
                                <Iconify icon="material-symbols:delete-outline" />&nbsp;Видалити
                            </MenuItem>)}
                            {!!participant.posa_form_elements_page && !tournament?.block_update_music_from_telegram && (
                                <MenuItem onClick={e => {handleCloseMenu(e); window.open(participant.posa_form_elements_page, '_blank').focus()}}>
                                    <Iconify icon="material-symbols:sports-gymnastics" />&nbsp;Форма обовʼязкових елементів
                                </MenuItem>
                            )}
                            {!!onUploadPayment && (<MenuItem onClick={e => {handleCloseMenu(e); inputRefPayment.current.click();}}>
                                <Iconify icon="fontisto:dollar" />&nbsp;Оновити квитанцію про сплату
                            </MenuItem>)}
                            {!!onUpload && !tournament?.block_update_music_from_telegram && !!participant?.music?.s3 && (
                                <MenuItem onClick={e => {handleCloseMenu(e); onDownloadMusic(participant.music);}}>
                                    <Iconify icon="material-symbols:download" />&nbsp;Завантажити музику
                                </MenuItem>
                            )}
                            {!!onUpload && !participant?.music?.approved && !tournament?.block_update_music_from_telegram && (<MenuItem onClick={e => {handleCloseMenu(e); inputRef.current.click();}}>
                                <Iconify icon="mdi:music" />&nbsp;Оновити музику
                            </MenuItem>)}
                        </Menu>
                        <VisuallyHiddenInput type="file" ref={inputRef} onChange={event => onUpload(event.target.files[0])} />
                        <VisuallyHiddenInput type="file" ref={inputRefPayment} onChange={event => onUploadPayment(event.target.files[0])} />
                    </Stack>
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={1}
                >
                    <Typography>{tournament?.additional_settings?.telegram?.labels?.phone ?? 'Телефон'}: {participant?.phone}</Typography>
                    <Typography>{tournament?.additional_settings?.telegram?.labels?.school ?? 'Студія/школа'}: {participant?.school}</Typography>
                    <Typography>{tournament?.additional_settings?.telegram?.labels?.coach ?? 'Тренер'}: {participant?.coach}</Typography>
                    <Typography>{tournament?.additional_settings?.telegram?.labels?.address ?? 'Адреса'}: {participant?.address}</Typography>
                    <Typography>{tournament?.additional_settings?.telegram?.labels?.title ?? 'Назва номеру'}: {participant?.title}</Typography>

                    {!!participant.payment && (
                        <Typography>Оплата: <a target="_blank" href={participant.payment.s3} download={participant.payment.filename}>{participant.payment.filename}</a></Typography>
                    )}

                    <Stack direction="row" alignItems='center' justifyContent="flex-start">
                        <div>
                            {!!participant.music?.length && new Date(participant.music?.length * 1000).toISOString().slice(14, 19)}
                            {!!participant.music?.length && ' '+participant.music.filename}
                        </div>
                    </Stack>

                    {!!participant?.music?.notify && (
                        <Typography>
                            {participant?.music?.notify}
                        </Typography>
                    )}

                    {!!expanded && !!participant?.music?.s3 &&
                        <Stack direction="row" alignItems='center' justifyContent="flex-start">
                            <ReactPlayer
                                url={participant?.music?.s3}
                                controls={false}
                                height={50}
                                style={{display: 'none'}}
                                ref={setPlayer}
                                playing={playing}
                                onProgress={onProgress}
                            />

                            {!playing && <IconButton color="inherit" onClick={onPlay}>
                                <Iconify icon="solar:play-bold" />
                            </IconButton>}

                            {!playing && !!progress && <IconButton color="inherit" onClick={onRepeat}>
                                <Iconify icon="mingcute:repeat-fill" />
                            </IconButton>}

                            {!!playing && <IconButton color="inherit" onClick={onPause}>
                                <Iconify icon="solar:pause-bold" />
                            </IconButton>}

                            {!!playing && <IconButton color="inherit" onClick={onStop}>
                                <Iconify icon="solar:stop-bold" />
                            </IconButton>}
                        </Stack>
                    }

                    {(tournament?.definition_participant_additional_parameters || [])
                        .filter(param => !param.hide_in_summary)
                        .filter(param => !(param.type === 'buttons' && !param.values[(participant.parameters || [])[param.key]]))
                        .map(param => (
                        <Typography key={param.key}>
                            {param.name}: {param.type === 'buttons' ? (param.values[(participant.parameters || [])[param.key] || '']) : (participant.parameters || [])[param.key] }
                            <br />
                        </Typography>
                    ))}

                    {!!participant.price && (
                        <Typography style={{color: 'green'}}>
                            Вартість участі: {participant?.price}
                        </Typography>
                    )}

                    {(tournament?.definition_documents_settings || [])
                        .filter(document => checkParticipantDocument(document))
                        .filter(document => !document.participant_type || document.participant_type === type.toLowerCase())
                        .map(document => {
                          const exists = (participant.documents || []).find(doc => doc.type === document.key)
                          const edit = (!exists || !exists.approved) && !tournament?.block_update_music_from_telegram  && (
                              <Link to={`/${bot}/${tournament.id}/document/${type}/${participant.id}/${document.key}?name=${document['name']}`}>
                                  <Iconify icon="material-symbols:edit" />
                              </Link>
                          )

                          if(exists){
                              return (
                                  <span>
                                      {exists.name} "{exists.filename}" {edit}
                                  <IconButton color="info" onClick={() => window.open(exists.s3, '_blank')}>
                                      <Iconify icon="flat-color-icons:download" />
                                  </IconButton>
                                  {(document.key.includes('video-proposal') || document.key.includes('video-participant')) && (
                                      <IconButton color="info" onClick={() => setViewVideo(exists)}>
                                          <Iconify icon="material-symbols:play-circle" />
                                      </IconButton>
                                  )}
                                  </span>
                              )
                          }else{
                              return (
                                  <span>
                                      Відсутній "{document.name}"&nbsp;
                                      {edit}
                                  </span>
                              )
                          }
                    })}

                    {!!participant.is_posa_form_elements_required && (
                        <>
                            <Typography>Форма обовʼязкових елементів</Typography>

                            <b>Елементи</b>
                            <table>
                                {(participant?.posa_form_elements?.form || []).map((element, i) => (
                                    <tr key={i}>
                                        <td><b>{i + 1}</b></td>
                                        <td>{element.code}</td>
                                        <td>{element.element}</td>
                                        <td>{element.value}</td>
                                    </tr>
                                ))}
                            </table>

                            <b>Комбінації</b>
                            <table>
                                {(participant?.posa_form_elements?.combinations || []).map((elements, i) => (
                                    <tr key={i}>
                                        <td><b>{i + 1}</b></td>
                                        <td>{elements.map(e => e.code).join(', ')}</td>
                                        <td>
                                            {elements.map(e => e.element).join(' + ')}
                                            {elements[0].adb && ('  (Super Bonus)')}
                                        </td>
                                        <td>{elements.map(e => e.value).join(' + ')}</td>
                                    </tr>
                                ))}
                            </table>
                        </>
                    )}
                </Stack>
            </AccordionDetails>
        </Accordion>
    )
}


export default Participant;
