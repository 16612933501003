import React, { useState, useEffect, createContext } from 'react';
import {
    Typography, Box, Button, Stack, AccordionActions, AccordionSummary,
    Card, CardHeader, Accordion, AccordionDetails, IconButton, TextField,
    InputAdornment,
} from '@mui/material';
import { useTournamentContext } from './../../context';
import axios from '../../utils/axios';
import { useParams } from "react-router-dom";
import RefreshIcon from '@mui/icons-material/Refresh';
import ClearIcon from '@mui/icons-material/Clear';

import Participant from './participant'

function HomePage() {
    const { tournament, participants, proposals, apiURL } = useTournamentContext();
    const [ search, setSearch ] = useState('')

    const refresh = () => {
        window.dispatchEvent(new CustomEvent("arena-refresh"));
    }

    const getFilteredParticipants = () => participants.filter(p => (p.name ?? '').toLowerCase().includes(search.toLowerCase()) || (p.title ?? '').toLowerCase().includes(search.toLowerCase()))
    const getFilteredProposals = () => proposals.filter(p => (p.name ?? '').toLowerCase().includes(search.toLowerCase()) || (p.title ?? '').toLowerCase().includes(search.toLowerCase()))

    const onUpload = (item, music, type) => {
        const formData = new FormData();
            formData.append('music', music);
            formData.append('id', item.id);
            formData.append('type', type);
            formData.append('id_tournament', item.id_tournament);

            axios.post(apiURL(`tournament/${item.id_tournament}/music`), formData, {
                headers: {
                  'content-type': 'multipart/form-data',
                }
            }).then(payload => {
                console.log(payload)
                if(!payload.id && payload.data?.error){
                    window.alert(payload.data?.error)
                }else{
                    window.alert('Дякуємо! Вашу музику успішно оновлено.')
                    refresh()
                }
            })
    }

    const onUploadPayment = (item, payment, type) => {
        const formData = new FormData();
        formData.append('payment', payment);
        formData.append('id', item.id);
        formData.append('type', type);
        formData.append('id_tournament', item.id_tournament);

        axios.post(apiURL(`tournament/${item.id_tournament}/payment`), formData, {
            headers: {
              'content-type': 'multipart/form-data',
            }
        }).then(payload => {
            console.log(payload)
            if(!payload.id && payload.data?.error){
                window.alert(payload.data?.error)
            }else{
                window.alert('Дякуємо! Вашу квитанцію про сплату успішно оновлено.')
                refresh()
            }
        })
    }

    const onDelete = proposal => {
        if(!window.confirm(`Ви точно впевнені, що бажаєте видалити заявку ${proposal?.name}`)){
            return
        }
        axios.delete(apiURL(`tournament/${proposal.id_tournament}/proposals/${proposal?.id}`)).then(payload => {
            refresh()
        })
    }

    return (<>
        <Card style={{padding: '0px 10px'}}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h5">
                    {tournament?.name}<br /> {tournament?.date_formatted}
                </Typography>

                <IconButton color="info" onClick={() => refresh()}>
                    <RefreshIcon />
                </IconButton>
            </Stack>
            <TextField
                fullWidth
                value={search}
                onChange={e => setSearch(e.target?.value ?? '')}
                placeholder="Пошук по імені"
                label="Пошук по імені"
                style={{margin: '5px 0px'}}
                InputProps={{
                  endAdornment: search && (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setSearch('')} edge="end">
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
            />
        </Card>
        {getFilteredParticipants().map(participant => (
            <Participant
                participant={participant}
                tournament={tournament}
                onUpload={file => onUpload(participant, file, 'PARTICIPANT')}
                onUploadPayment={file => onUploadPayment(participant, file, 'PARTICIPANT')}
            />
        ))}
        {getFilteredProposals().map(proposal => (
            <Participant
                participant={proposal}
                tournament={tournament}
                onUpload={file => onUpload(proposal, file, 'PROPOSAL')}
                onUploadPayment={file => onUploadPayment(proposal, file, 'PROPOSAL')}
                onDelete={() => onDelete(proposal)}
            />
        ))}
    </>);
}

export default HomePage;
