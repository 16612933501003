import { useState, useEffect, createContext } from 'react';
import {
    Box
} from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage'
import RegisterPage from './pages/ParticipantPage/Register'
import EditPage from './pages/ParticipantPage/Edit'
import DocumentPage from './pages/ParticipantPage/Document'
import Toolbar from './Toolbar'
import { useTournamentContext } from './context';

function App() {

    const { user } = useTournamentContext();

    if(!user){
        return (<></>)
    }

    return (
        <Router>
            <Box sx={{ flexGrow: 1 }} style={{backgroundColor: 'white'}}>
                <Toolbar />
                <Routes>
                    <Route path="/:bot/:id_tournament" element={<HomePage />} />
                    <Route path="/:bot/:id_tournament/registration" element={<RegisterPage />} />
                    <Route path="/:bot/:id_tournament/edit/:type/:id_participant" element={<EditPage />} />
                    <Route path="/:bot/:id_tournament/document/:type/:id_participant/:document_type" element={<DocumentPage />} />
                </Routes>
            </Box>
        </Router>
    );
}

export default App;
