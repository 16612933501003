import React, { useState, useEffect, createContext } from 'react';
import {
    Typography, Box, Button, Stack, AccordionActions, AccordionSummary,
    Card, CardHeader, Accordion, AccordionDetails, TextField, FormControl,
    InputLabel, Select, MenuItem, Alert,
} from '@mui/material';

import { useTournamentContext } from './../../context';
import axios from '../../utils/axios';
import { Link, useParams, useNavigate } from "react-router-dom";
import Form from './Form'

function RegisterPage() {
    const navigate = useNavigate();
    const { user, bot, apiURL, tournament } = useTournamentContext();

    const [ data, setData ] = useState({})
    const [ registered, setRegistered ] = useState(false)
    const [ error, setError ] = useState(false)
    const [ inProgress, setInProgress ] = useState(false)

    useEffect(() => {
        setData({})
    }, [tournament])

    const register = async (event) => {
        event.preventDefault()
        event.stopPropagation()
        if(inProgress){
            return
        }
        setInProgress(true)

        let values = Object.assign({}, data)
        for(const param of Object.keys(values.parameters)){
            if(!document.getElementById(`parameter-${param}`)){
                delete values.parameters[param]
            }
        }

        const payload = await axios.post(apiURL(`tournament/${tournament.id}/proposals/register`), values).catch(e => {
            setError("Вибачте, сталась помилка. Перезавантажте сторінку і спробуйте ще раз.")
        })

        setInProgress(false)

        if(payload?.data?.id){
            setRegistered(true)
            setError(null)

            window.dispatchEvent(new CustomEvent("arena-registered", {
                detail: payload?.data
            }));
        }else{
            setError("Вибачте, сталась помилка. Перезавантажте сторінку і спробуйте ще раз.")
        }
    }

    const addNew = () => {
        setData({
            ...data,
            title: '',
            parameters: {},
        })
        setRegistered(false)
    }

    const isHidden = (key, param) => {
        return (param['hidden'] ?? []).includes(`${key}`)
    }

    return (
        <Stack spacing={1} style={{margin: '5px 10px'}} justifyContent="center">
              <Link to={`/${bot}/${tournament?.id}`}>Повернутись до моїх учасників</Link>

              <Typography variant="h5">
                  Реєстрація на {tournament?.name}, {tournament?.date_formatted}
              </Typography>

            {!!error && (
                <Alert severity="error">
                    {error}
                </Alert>
            )}

            {!registered && (
                <form onSubmit={(e) => register(e)}>
                    <Form
                        data={data}
                        onChange={value => setData(value)}
                    />
                    <Button type="submit" variant="contained" color="warning" style={{margin: '5px auto'}}>
                        Зареєструвати
                    </Button>
                </form>
            )}


            {!!registered && (
                <>
                    <Alert severity="success">
                        {tournament?.registration_website_success_notes ?? 'Вас успішно зареєстровано.'}
                    </Alert>
                    <Button onClick={() => addNew()} variant="contained" color="warning">
                        {(tournament?.additional_settings?.telegram?.labels || {})['register-another-participant'] ?? 'Додати учасника'}
                    </Button>
                </>
            )}

            <br />
            <br />
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </Stack>
    );
}

export default RegisterPage;
